.root {
	display: grid;
	gap: var(--hdsplus-spacing-12);
	composes: g-grid from global;
	composes: g-grid-container from global;
}

.text {
	grid-column: 1 / -1;
	display: grid;

	@media (--medium-up) {
		grid-column: 1 / span 7;
	}
	@media (--large) {
		grid-column: 1 / span 6;
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}

.icon {
	height: 100%;
	display: flex;
}

.items {
	grid-column: 1 / -1;
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: grid;
	gap: var(--hdsplus-spacing-09);
	grid-template-columns: repeat(var(--mobileColumns), 1fr);
	
	@media (--medium-up) {
		grid-template-columns: repeat(var(--tabletColumns), 1fr);
		gap: var(--hdsplus-spacing-07);
	}

	@media (--large) {
		grid-template-columns: repeat(var(--desktopColumns), 1fr);
		gap: var(--hdsplus-spacing-08);
	}
}

.item {
	display: flex;
	flex-direction: column;
}

.itemIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: var(--hdsplus-spacing-03);
	width: 50px;
	height: 50px;
	background-color: var(--token-color-surface-strong);
	padding: 13px;
	margin-bottom: var(--hdsplus-spacing-05);
	color: var(--token-color-foreground-primary);

	@nest :global(.hashidaysConference) & { 
		color: var(--waypoint-link);
		background-color: transparent;
	}
}

.itemHeader {
	color: var(--token-color-foreground-strong);
	font-weight: 700;
	margin-bottom: var(--hdsplus-spacing-03);
}

.itemDescription {
	color: var(--token-color-foreground-primary);
}

.standaloneLink {
	margin-top: var(--hdsplus-spacing-05);
}