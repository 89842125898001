.root {
	composes: g-grid-container from global;
}

.content {
	composes: g-grid from global;
	align-items: center;
	text-align: center;
	margin-bottom: var(--hdsplus-spacing-10);

	& > * {
		grid-column: 1 / -1;

		@media (--medium-up) {
			grid-column: 3 / 11;
		}
	}

	@media (--medium-up) {
		margin-bottom: var(--hdsplus-spacing-13);
	}
}

.logoGroup + .logoGroup {
	padding-top: var(--hdsplus-spacing-10);

	@media (--medium-up) {
		padding-top: var(--hdsplus-spacing-13);
	}
}

.logoGroupHeading {
	margin-bottom: var(--hdsplus-spacing-08);
}

.logos {
	grid-column: 1 / -1;
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: grid;
	gap: var(--hdsplus-spacing-09);
	grid-template-columns: repeat(var(--mobileColumns), 1fr);

	@media (--medium-up) {
		grid-template-columns: repeat(var(--tabletColumns), 1fr);
		gap: var(--hdsplus-spacing-07);
	}

	@media (--large) {
		grid-template-columns: repeat(var(--desktopColumns), 1fr);
		gap: var(--hdsplus-spacing-08);
	}
}

.logo {
	display: flex;
	flex-direction: column;
}

.image {
	border-radius: var(--hdsplus-spacing-03);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	border: 1px solid var(--token-color-border-primary);
	background-color: var(--token-color-surface-interactive);
	transition: 0.25s ease;
	transition-property: background-color, box-shadow;

	@nest .featured & {
		background-color: var(--token-color-surface-faint);
	}

	@nest .small & {
		padding: var(--hdsplus-spacing-10) var(--hdsplus-spacing-11);
	}

	@nest .medium & {
		padding: var(--hdsplus-spacing-11) var(--hdsplus-spacing-12);
	}

	@nest .large & {
		padding: var(--hdsplus-spacing-12) var(--hdsplus-spacing-13);
	}

	&:hover {
		background-color: var(--token-color-surface-interactive-hover);
		cursor: pointer;
	}

	& img {
		height: auto;
		max-width: 100%;

		/* Restricts logo height to ensure proper display on mobile */
		max-height: 50px;
		object-fit: contain;
	}
}
